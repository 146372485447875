/******/
@import url('https://fonts.googleapis.com/css2?family=Alkatra:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Eczar:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alkatra:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Eczar:wght@400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer{
    .btn {
        background: linear-gradient(92.23deg,
            #FFFF 21.43%,
            #b936ee 50.63%,
            #3bace2 100%,
            #406aff 117.04%
        );
        box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
    }

    
}

.btn:hover {
    background: linear-gradient(
        92.23deg,
        #406aff 21.43%,
        #3bace2 50.63%,
        #b936ee 100%,
        #ff56f6 117.04%
    );
  }
  
  .gradient,
  .active {
    background: linear-gradient(
        92.23deg,
        #406aff 21.43%,
        #3bace2 50.63%,
        #b936ee 100%,
        #ff56f6 117.04%
    );
  }
  
  .active {
    color: #fff;
    padding: 1rem;
    border-radius: 100%;
  }

     /* ************************** HERO *********************************** */
    .hero-ttle {
      @apply p-5 text-4xl  font-extrabold text-center text-white;
      @apply md:text-5xl lg:text-6xl ;
    
    }
  
    .hero-subttle {
      @apply text-center text-4xl  font-extrabold  text-white;
      @apply md:text-4xl;
    }
  
  
    .hero-boton {
      @apply bg-blue-950  bottom-5 m-1 px-5 py-3  font-bold  text-white rounded-3xl hover:bg-opacity-25;
      @apply text-sm ml-[17%] md:ml-[32%] lg:ml-[40%] xl:ml-[45%] mr-4 mt-5;
    }
  

  @layer base {
    body {
      @apply  text-lg leading-8 text-white;
    }
    .h2 {
      @apply text-[32px] mb-6 tracking-[10%] uppercase;
    }
    .h3 {
      @apply font-semibold text-[26px] mb-6 leading-[46px];
    }
    .btn {
      @apply rounded-full  text-white font-medium;
    }
    .btn-sm {
      @apply h-[48px] px-6 text-sm;
    }
    .btn-lg {
      @apply h-[56px] px-10 text-base;
    }
    .btn-link {
      @apply text-base;
    }
    .text-gradient {
      @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3];
    }
    .section {
      @apply py-8 lg:py-24 lg:h-screen flex items-center;
    }
  }

  @layer base {
    body {
      @apply  bg-gray-200;
    }
    .dropdown {
      @apply w-full lg:max-w-[296px] cursor-pointer hover:shadow-2xl hover:shadow-white;
    }
    .dropdown-btn {
      @apply flex h-[64px] items-center px-[18px] border rounded-lg;
    }
    .dropdown-icon-primary {
      @apply text-2xl mr-[18px] text-violet-700;
    }
    .dropdown-icon-secondary {
      @apply text-2xl text-violet-700 ml-20;
    }
    .dropdown-icon-tertiary {
      @apply text-2xl text-violet-700 ml-7;
    }
    .dropdown-icon-quaternary{
      @apply text-2xl text-violet-700 ml-7;
    }
    .dropdown-menu {
      @apply px-6 py-8 text-[15px] space-y-6 shadow-md bg-white absolute w-full z-10 list-none rounded-b-lg;
    }
  }
  
